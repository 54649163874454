import Head from 'next/head';

const Index = () => {
  return (
    <Head>
      <meta key="robots" name="robots" content="noindex, nofollow" />
      <meta key="googlebot" name="googlebot" content="noindex, nofollow" />
    </Head>
  );
};

export default Index;
